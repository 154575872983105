import { IonAlert, IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonLabel, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import CommonDataTable from "../../components/datatable";
import { addOutline, close, closeCircle, searchOutline } from "ionicons/icons";
import { Modal } from "react-bootstrap";
import Authservice from "../../Services/user.service";
import Select, { components } from 'react-select';
import moment from "moment";
import CommonModal from "../../components/popipInfo";
import HeadFoot from "../Head";



const MarketManagement: React.FC = () => {

   const [filterText, setFilterText] = React.useState("");
   const [productList, setProductList] = useState([])
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
   const [usertoken] = useState(sessionStorage.getItem("token"));
   const [userRole] = useState(sessionStorage.getItem("UserData"));
   const [userId] = useState(sessionStorage.getItem("UserId"));
   const [modalopen1, setModalopen1] = useState(false);
   const [index, setIndex] = useState(0);
   const [productname, setProductname] = useState();
   const [brandname, setBrandname] = useState();
   const [price, setPrice]: any = useState("");
   const [date, setDate] = useState("");
   const [id, setId] = useState("")
   const [productCode, setProductCode] = useState("")
   const [description, setDescription] = useState("");
   const [productDetails, setProductDetails] = useState("")
   const [productLocation, setProductLocation] = useState("")
   const [isActive, setIsActive] = useState(false)
   const [isDelete, setIsDelete] = useState(false)
   const [isVetoed, setIsVetoed] = useState(false)
   const [vetoedBy, setVetoedBy] = useState("")
   const [vetoedOn, setVetoedOn] = useState("")
   const [modifiedBy, setModifiedBy] = useState("")
   const [modifiedOn, setModifiedOn] = useState("")
   const [category, setCategory] = useState("")
   const [categoryList, setCategoryList] = useState([])
   const [psize, setPsize] = useState("");
   const [image, setImage]: any = useState([]);
   const [imageFiles, setImageFiles]: any = useState([]);
   const [name, setName] = useState("");
   const [fileName, setFileName] = useState("");
   const [formErrors, setFormErrors]: any = useState({});
   const [showLoading, setShowLoading] = useState(false)
   const oneTimeRender = useRef(false)
   const [iserror, setIserror] = useState(false)
   const [errorMsg, setErrorMsg] = useState("")
   const [successAlert, setSuccessAlert] = useState(false)
   const [successMsg, setSuccessMsg] = useState("")
   const [vendorsList, setVendorsList] = useState([])
   const [selectedVendor, setSelectedVendor]: any = useState(null)
   const [Commonmodal, setCommonmodal] = useState(false)
   const [CommonModalMesg, setCommonModalMesg] = useState("")
   const [Commmonmodaltitle, setCommonmodaltitle] = useState("")

   if (productList != undefined && productList.length > 0) {
      productList.forEach((item: any, index: any) => { item.serial = index + 1; });
   }

   var tableData: any = productList.filter(
      (item: any) =>
         JSON.stringify(item)
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
   );


   const subHeaderComponent = useMemo(() => {
      const handleClear = () => {
         if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
         }
      };
      return (
         <IonRow className="table_btn_container">
            <div>
               <IonButton onClick={() => {
                  setModalopen1(true)
                  // back(); setIndex(1);
                  // setMenuItems((prev: any) =>
                  //     prev.map((item: any) => ({
                  //         ...item,
                  //         isOpen: false,
                  //     }))
                  // );
               }} className="admin_add_btn">
                  <IonIcon src={addOutline} />
                  <span>Add Product</span>
               </IonButton>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
               <div className="select-search-container">
                  <div className="radio-search-container">
                     <IonInput placeholder="Search..."
                        onIonChange={(e: any) => setFilterText(e.detail.value)}
                        value={filterText} style={{ textAlign: "left" }} className="search-box"></IonInput>
                     <IonIcon
                        icon={searchOutline}
                        className="search-icon"
                     />
                  </div>
               </div>
            </div>
         </IonRow>
      );
   }, [filterText, resetPaginationToggle, productList]);

   const preventdata = (e: any) => {
      e.target.addEventListener("wheel", function (s: any) { s.preventDefault() }, { passive: false })
      e.target.addEventListener('keydown', function (u: any) {
         if (u.which === 38 || u.which === 40) {
            u.preventDefault();
         }
      })
      // e.target.addEventListener("keydown", function (d: any) { d.preventDefault() })
   }

   useEffect(() => {
      if (oneTimeRender.current === false) {
         oneTimeRender.current = true
         getAllProduct()
         getAllVendotrs()
      }
   }, [])

   const getAllVendotrs = () => {
      setShowLoading(true)
      Authservice.getVendorsList(usertoken, "", userId).then((response: any) => {
         if (response.data.length > 0) {
            console.log(response, "response")
            // setProductList(response.data)
            setVendorsList(response.data.map((e: any) => {
               return { label: e.vendorName, value: e.userId }
            }
            ))
         }
         setShowLoading(false)
      })
         .catch((error: any) => {
            console.log(error, "error")
            setShowLoading(false)
         })
   }


   const getAllProduct = () => {
      setShowLoading(true)
      Authservice.getAllProductsListAdm(userId, "", usertoken).then((response: any) => {
         if (response.data.length > 0) {
            console.log(response, "response")
            setProductList(response.data)
         }
         getAllProductCategory()
         setShowLoading(false)
      })
         .catch((error: any) => {
            console.log(error, "error")
            setShowLoading(false)
         })
   }

   const getAllProductCategory = () => {
      setShowLoading(true)
      Authservice.getAllProductCategory(userId, "", usertoken).then((response: any) => {
         if (response.data.length > 0) {
            const data = response.data
               .map((e: any) => ({ label: e.category, value: e.categoryCode }))
               .sort((a: any, b: any) => a.label.localeCompare(b.label)); // Sort by label

            setCategoryList(data);
         }
         setShowLoading(false)
      })
         .catch((error: any) => {
            console.log(error, "error")
            setShowLoading(false)
         })
   }

   const column = [
      {
         name: "S.No",
         sortable: true,
         width: "90px",
         selector: (item: any) => item.serial,
         cell: (item: any) => (
            <div onClick={() => viewProduct(item)} className="center_col_tab">
               {item.serial}
            </div>
         ),
      },
      {
         name: "Product Image",
         sortable: false,
         width: "150px",
         selector: (item: any) => item.image,
         cell: (item: any) => (
            <div onClick={() => viewProduct(item)} className="center_col_tab">
               <IonImg style={{ width: "50px", height: "50px", cursor: "pointer" }} alt="img" src={item.productGalleryDtos[0]?.imageUrl + "?v=" + new Date()}></IonImg>
            </div>
         ),
      },
      {
         name: "Product Code",
         selector: (item: any) => item.productCode,
         sortable: true,
         cell: (item: any) => (
            <div onClick={() => viewProduct(item)} className="left_col">
               <div style={{ cursor: "pointer" }} >{item.productCode}</div>
            </div>
         )
      },
      {
         name: "Product Name",
         selector: (item: any) => item.productDesc,
         sortable: true,
         // width: "150px",
         style: {
            textAlign: 'left'
         },
         cell: (item: any) => (
            <div onClick={() => viewProduct(item)} className="left_col">
               <div style={{ cursor: "pointer" }} >{item.productDesc}</div>
            </div>
         )
      },
      {
         name: "Product Details",
         selector: (item: any) => item.productDetail,
         sortable: false,
         //width: "50%",
         cell: (item: any) => (
            <div onClick={() => viewProduct(item)} style={{ textAlign: "justify" }}>
               <div className="textDes threelineCls" >{item.productDetail}</div>
            </div>
         )
      },
      {
         name: "Price",
         selector: (item: any) => item.price,
         sortable: false,
         //width: "50%",
         cell: (item: any) => (
            <div onClick={() => viewProduct(item)} style={{ textAlign: "justify" }}>
               <div className="textDes threelineCls" >{item.price}</div>
            </div>
         )
      },
      {
         name: "Valid Till",
         selector: (item: any) => item.validTill,
         sortable: true,
         cell: (item: any) => {
            const date = new Date(item.validTill).toLocaleDateString('en-US', {
               day: 'numeric',
               month: 'numeric',
               year: 'numeric'
            });
            return (
               <div onClick={() => viewProduct(item)}>
                  {date}
               </div>
            )
         },
      }
   ]

   const validation = (e: any) => {
      if (e == 1) {
         if (name.length === 0) {
            setFormErrors({ ...formErrors, name: "Please Enter The Name" })
         }
      }
      if (e == 3) {
         if (price.length === 0) {
            setFormErrors({ ...formErrors, price: "Please Enter The Price" })
         }
      }
      if (e == 5) {
         if (psize.length === 0) {
            setFormErrors({ ...formErrors, size: "Please Enter The Size" })
         }
      }
   }

   const namechange = (e: any) => {
      if (e.detail.value !== "") {
         setFormErrors({ ...formErrors, name: "" })
      }
      setName(e.detail.value!)
   }
   const brandChange = (e: any) => {
      if (e.detail.value !== "") {
         setFormErrors({ ...formErrors, brand: "" })
      }
   }
   const priceChange = (e: any) => {
      if (e.detail.value !== "") {
         setFormErrors({ ...formErrors, price: "" })
      }
      setPrice(e.detail.value!)
   }

   const imageinputref: any = useRef(null)
   const imageinputref1: any = useRef(null)

   const handleimage = (e: any) => {
      const files = e.target.files;
      if (files) {
         const newImages = [...image];
         Array.from(files).forEach((file: any) => {
            if (newImages.length < 10 && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "video/mp4")) {
               const reader = new FileReader();
               reader.onload = () => {
                  newImages.push({ imageUrl: reader.result, id: null });
                  console.log({ imageUrl: reader.result, id: null })
                  setImage([...newImages]);
                  setImageFiles([...imageFiles, ...files])
               };
               reader.readAsDataURL(file);
            }
         });
      }
      console.log(e.target.files);

   };

   const handleButtonClick = (e: any) => {
      if (e == 1) {
         if (imageinputref.current) {
            imageinputref.current.click();
         }
      } else {
         if (imageinputref1.current) {
            imageinputref1.current.click();
         }
      }
   };

   const submit = () => {
      var productDto = {
         "id": id,
         "productCode": productCode,
         "productDesc": description,
         "productDetail": productDetails,
         "validTill": date,
         "vendor": userRole == "ROLE_VENDOR" ? userId : selectedVendor?.value ? selectedVendor.value : "",
         "productLocation": productLocation,
         "price": parseFloat(price),
         "isActive": isActive,
         "isDeleted": isDelete,
         "categoryCode": category,
         "isVetoed": isVetoed
      }
      Authservice.addOrUpdateProducts(usertoken, userId, "", productDto, imageFiles).then((response: any) => {
         console.log(response.data, "res")
         if (response.data === "success") {
            if (productCode === "") {
               setSuccessMsg("Product created successfully")
            }
            else {
               setSuccessMsg("Product updated successfully")
            }
            setModalopen1(false)
            reset()
            setSuccessAlert(true)
         }
         else {
            setShowLoading(false)
            setIserror(true)
            setErrorMsg("Something wen`t wrong.Please try again later")
         }
      })
         .catch((error: any) => {
            console.log(error, "error`")
            setModalopen1(false)
            setShowLoading(false)
            setIserror(true)
            setErrorMsg("Something wen`t wrong.Please try again later")
         })
   }

   const viewProduct = (item: any) => {
      setShowLoading(true)
      Authservice.getProductByProductCode(userId, usertoken, usertoken, item.productCode).then((response) => {
         console.log(response.data)
         if (response.data.productCode) {
            setId(response.data.id)
            setProductCode(response.data.productCode)
            setDescription(response.data.productDesc)
            setProductDetails(response.data.productDetail)
            setImage(response.data.productGalleryDtos)
            setCategory(response.data.categoryCode)
            setPrice(response.data.price)
            setProductLocation(response.data.productLocation)
            setIsActive(response.data.isActive)
            setIsDelete(response.data.isDeleted)
            if (response.data.vetoedBy) {
               setIsVetoed(true)
               var vetoedDate = new Date(response.data.vetoedOn)
               var vetoedFormatDate = moment(vetoedDate).format("DD-MM-YYYY")
               setVetoedBy(response.data.vetoedBy)
               setVetoedOn(vetoedFormatDate)
            }
            else {
               setIsVetoed(false)
            }
            var modifiedDate = new Date(response.data.modifiedOn)
            var modifiedFormatDate = moment(modifiedDate).format("DD-MM-YYYY")
            setModifiedOn(modifiedFormatDate)
            setModifiedBy(response.data.modifiedBy || "")
            setIsVetoed(response.data.vetoedBy ? true : false)
            var date = new Date(response.data.validTill)
            console.log(date, "date")
            var formatDate = moment(date).format("YYYY-MM-DD")
            setSelectedVendor({ label: response.data.vendorDetails.vendorName, value: response.data.vendorDetails.userId })
            setDate(formatDate)
            setModalopen1(true)
            setShowLoading(false)
         }
         else {
            setShowLoading(false)
            setIserror(true)
            setErrorMsg("Something wen`t wrong.Please try again later")
         }
      })
         .catch((error: any) => {
            setShowLoading(false)
            setIserror(true)
            setErrorMsg("Something wen`t wrong.Please try again later")
         });
   };

   const removeImg = (item: any, i: any) => {
      if (item.id === "" || item.id === null) {
         setImage(image.filter((_: any, index: any) => index !== i))
         setImageFiles(imageFiles.filter((_: any, index: any) => index !== i))
      }
      else {
         setShowLoading(true)
         Authservice.deleteProductImg(userId, usertoken, usertoken, item.id).then((response: any) => {
            if (response.data === "success") {
               setImage(image.filter((_: any, index: any) => index !== i))
               setImageFiles(imageFiles.filter((_: any, index: any) => index !== i))
               setCommonModalMesg("Image removed successfully")
               setCommonmodaltitle("Information")
               setCommonmodal(true)
            }
            else {
               setCommonmodal(true)
               setCommonModalMesg("Something wen`t wrong.Please try again later")
               setCommonmodaltitle("Error")
            }
            setShowLoading(false)
         })
            .catch((error: any) => {
               console.log(error, "error")
               setShowLoading(false)
               setCommonmodal(true)
               setCommonModalMesg("Something wen`t wrong.Please try again later")
               setCommonmodaltitle("Error")
            })
      }
   }

   const modalClose = () => {
      setCommonmodal(false)
   }

   const adminSelectionChange = (e: any, label: any) => {
      if (label === "active") {
         if (e.detail.checked) {
            setIsActive(true)
         }
         else {
            setIsActive(false)
         }
      }
      else if (label === "delete") {
         if (e.detail.checked) {
            setIsDelete(true)
         }
         else {
            setIsDelete(false)
         }
      }
      else if (label === "vetoed") {
         if (e.detail.checked) {
            setIsVetoed(true)
         }
         else {
            setIsVetoed(false)
         }
      }
   }

   const customStyles = {
      control: (provided: any) => ({
         ...provided,
         border: "1px solid black",
         boxShadow: 'none',
         fontSize: '15px',
         // height: '44px',
         outline: 'none',
         textAlign: "left",
         width: "300px"
      }),
   };
   const reset = () => {
      setId("")
      setProductCode("")
      setDescription("")
      setProductDetails("")
      setImage("")
      setCategory("")
      setPrice("")
      setProductLocation("")
      setIsActive(false)
      setIsDelete(false)
      setIsVetoed(false)
      setVetoedBy("")
      setVetoedOn("")
      setModifiedOn("")
      setModifiedBy("")
      setDate("")
   }

   const hanldeVendorChange = (e: any) => {
      if (e) {
         setSelectedVendor(e)
      } else {
         setSelectedVendor(null)
      }
   }


   return (
      <IonPage>
         {userRole === "ROLE_ADMIN" && <>
            <AdminHead User={"user"} Role={"Role"} Name={"Product mangement"} />
            <Sidebar /></>}
         <IonContent>
            <IonLoading message="Loading..." spinner={null}
               isOpen={showLoading}
            />
            <IonAlert
               isOpen={iserror}
               onDidDismiss={() => setIserror(false)}
               cssClass="my-custom-class"
               header={"Error!"}
               message={errorMsg}
               buttons={["Ok"]}
            />
            <IonAlert
               isOpen={successAlert}
               onDidDismiss={() => setSuccessAlert(false)}
               cssClass="my-custom-class"
               header={"Success"}
               message={successMsg}
               onWillDismiss={() => {
                  if (successMsg.startsWith("Image")) {
                     setSuccessAlert(false)
                  }
                  else {
                     window.location.reload()
                  }
               }}
               buttons={["Ok"]}
            />
            {userRole !== "ROLE_ADMIN" && <HeadFoot User={"Product mangement"} Role="Role" Name='Home,Product mangement' />}
            <div className={userRole === "ROLE_ADMIN" ? "container_middle_table page-wrapper admin_background_color" : "page_content_padding tableContainev2"}>
               <IonRow className="data_table" style={{ paddingTop: '10px' }}>
                  <div style={{ width: "100%" }}>
                     <CommonDataTable
                        columns={column}
                        data={tableData}
                        noDataMessage="Sorry, No data available"
                        onRowClick={viewProduct}
                        isSubHeader={true}
                        subHeaderComponent={subHeaderComponent}
                     />
                  </div>
               </IonRow>
               <Modal show={modalopen1} className="admin_modal">
                  <Modal.Body>
                     <div className="donation-modal-header admin-user-modal-header">
                        {productCode === "" ?
                           <h5 className="donation-modal-title">Add Material</h5>
                           :
                           <h5 className="donation-modal-title">Edit Material</h5>
                        }
                        <div>
                           <IonIcon src={close} className="donation-modal-close-icon close_icon" onClick={() => { setModalopen1(false); reset() }} />
                        </div>
                     </div>
                     <IonGrid >
                        <form>
                           <IonRow className="admin_controller_menu_container">
                              {userRole !== "ROLE_VENDOR" && <Select
                                 className="custom-select-dark"
                                 options={vendorsList}
                                 value={selectedVendor}
                                 placeholder="Select a Vendor"
                                 isClearable
                                 styles={customStyles}
                                 onChange={hanldeVendorChange}
                              />}
                              <IonCol size-lg="2" size-md="2" size-xs="12" size-sm="12">
                                 <IonCheckbox style={{ zIndex: 0 }} className="multiple_checkbox_style" checked={isActive} onIonChange={(e) => adminSelectionChange(e, "active")} />
                                 <IonLabel>isActive</IonLabel>
                              </IonCol>
                              <IonCol size-lg="2" size-md="2" size-xs="12" size-sm="12">
                                 <IonCheckbox className="multiple_checkbox_style" checked={isDelete} onIonChange={(e) => adminSelectionChange(e, "delete")} />
                                 <IonLabel>isDelete</IonLabel>
                              </IonCol>
                              <IonCol size-lg="2" size-md="2" size-xs="12" size-sm="12">
                                 <IonCheckbox className="multiple_checkbox_style" checked={isVetoed} onIonChange={(e) => adminSelectionChange(e, "vetoed")} />
                                 <IonLabel>isVetoed</IonLabel>
                              </IonCol>
                              {!isVetoed || vetoedBy !== "" &&
                                 <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                                    <IonLabel>Vetoed By :</IonLabel>
                                    <IonText>{vetoedBy}</IonText>
                                 </IonCol>
                              }
                              {!isVetoed || vetoedOn !== "" &&
                                 <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                                    <IonLabel>Vetoed On :</IonLabel>
                                    <IonText>{vetoedOn}</IonText>
                                 </IonCol>
                              }
                              {modifiedBy !== "" &&
                                 <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                                    <IonLabel>Modified By :</IonLabel>
                                    <IonText>{modifiedBy}</IonText>
                                 </IonCol>
                              }
                              {modifiedOn !== "" &&
                                 <IonCol size-lg="4" size-md="4" size-xs="12" size-sm="12">
                                    <IonLabel>Modified On :</IonLabel>
                                    <IonText>{modifiedOn}</IonText>
                                 </IonCol>
                              }
                           </IonRow>
                           <IonRow>
                              <IonCol sizeXs="12" sizeMd="12">
                                 <IonLabel className="label_align">Product Description <span style={{ color: "red" }}>*</span></IonLabel>
                                 <div>
                                    <textarea
                                       className="product_textarea"
                                       placeholder="Product Description"
                                       value={description}
                                       onChange={(e) => setDescription(e.target.value)}
                                       required
                                    />
                                 </div>
                              </IonCol>
                              <IonCol sizeXs="12" sizeMd="12">
                                 <IonLabel className="label_align">Product Details <span style={{ color: "red" }}>*</span></IonLabel>
                                 <div>
                                    <textarea
                                       className="product_textarea"
                                       placeholder="Product Details"
                                       value={productDetails}
                                       onChange={(e) => setProductDetails(e.target.value)}
                                       required
                                    />
                                 </div>
                              </IonCol>
                              <IonCol sizeXs="12" sizeMd="12">
                                 <IonLabel className="label_align">Product Location <span style={{ color: "red" }}>*</span></IonLabel>
                                 <div>
                                    <textarea
                                       className="product_textarea"
                                       placeholder="Product Location"
                                       value={productLocation}
                                       onChange={(e) => setProductLocation(e.target.value)}
                                       required
                                    />
                                 </div>
                              </IonCol>
                              <IonCol sizeXs="12" sizeMd="6">
                                 <IonLabel className="label_align">Price <span style={{ color: "red" }}>*</span></IonLabel>
                                 <div>
                                    <IonInput
                                       placeholder="Price"
                                       type="number"
                                       value={price}
                                       min={0}
                                       onBlur={() => validation(3)}
                                       onFocus={(e) => preventdata(e)}
                                       onIonChange={(e) => priceChange(e)}
                                       required
                                    ></IonInput>
                                 </div>
                                 {formErrors.price !== "" && <span className="alert_input_bottom " style={{ marginLeft: "3%" }}>{formErrors.price}</span>}
                              </IonCol>
                              <IonCol sizeXs="12" sizeMd="6">
                                 <IonLabel className="label_align">Valid Till <span style={{ color: "red" }}>*</span></IonLabel>
                                 <div>
                                    <IonInput
                                       type="date"
                                       value={date}
                                       onIonChange={(e) => setDate(e.detail.value!)}
                                    />
                                 </div>
                              </IonCol>
                              <IonCol sizeXs="12" sizeMd="6">
                                 <IonLabel className="label_align">Category <span style={{ color: "red" }}>*</span></IonLabel>
                                 <div>
                                    <Select className="select-stars off_input" options={categoryList} value={categoryList.filter((item: any) => item.value === category)} onChange={(e: any) => setCategory(e.value)} />
                                 </div>
                              </IonCol>
                              <IonCol sizeMd="6" sizeXs="12">
                                 <IonLabel className="label_align">Gallery <span style={{ color: "red" }}>*</span></IonLabel>
                                 <IonRow className="image_div" style={{ margin: "1px 0px" }}>
                                    <IonCol size="6" className="center_col image_content">
                                       {imageFiles != "" ? <span className="image_content_spn">{fileName}</span> :
                                          <span className="image_content_spn">No file Chosen <span style={{ color: "red" }}>*</span></span>}
                                    </IonCol>
                                    <IonCol size="6" className="image_content">
                                       <input
                                          className={imageFiles != "" ? "" : "image_chose"}
                                          type="file"
                                          ref={imageinputref1}
                                          multiple
                                          onChange={(e) => handleimage(e)}
                                          style={{ display: 'none' }}
                                       />
                                       <IonButton
                                          onClick={() => handleButtonClick(2)}
                                          className="admin_img_btn image_content"
                                       ><span className="button_padding">Choose File</span>
                                       </IonButton>
                                    </IonCol>

                                 </IonRow>
                              </IonCol>
                           </IonRow>
                        </form>
                        {image.length === 0 ? (
                           <div></div>
                        ) : (
                           <IonRow className="ion-justify-content-center">
                              {image ? image.map((item: any, i: number) => (
                                 <IonCol sizeLg="4" size="4" key={item.id || i}>
                                    <div style={{ display: "flex", justifyContent: "right" }}>
                                       <IonIcon
                                          src={closeCircle}
                                          tabIndex={0}
                                          size="small"
                                          style={{ cursor: "pointer", color: '#576F9F' }}
                                          onClick={() => removeImg(item, i)}
                                       />
                                    </div>
                                    <IonImg
                                       src={item.imageUrl}
                                       tabIndex={1}
                                       className="img"
                                    />
                                 </IonCol>
                              )) : "No Data Available"}
                           </IonRow>
                        )}
                        <IonRow className="admin-submit-cancel">
                           <IonCol size="12">
                              <IonRow style={{ alignItems: "center" }}>
                                 <IonCol style={{ display: "flex", justifyContent: "flex-end" }} size="6">
                                    <div style={{ width: "fit-content" }} className={description.length === 0 ||
                                       price.length === 0 ||
                                       productDetails === "" ||
                                       productLocation === "" ||
                                       category === "" ||
                                       date === "" ||
                                       image.length === 0 ? "disableCursor" : ""}>
                                       <IonButton
                                          disabled={
                                             description.length === 0 ||
                                             price.length === 0 ||
                                             productDetails === "" ||
                                             productLocation === "" ||
                                             category === "" ||
                                             date === "" ||
                                             image.length === 0
                                          }
                                          onClick={submit}
                                          type="submit"
                                          className="admin_add_btn"
                                       >
                                          <span className="button_padding">Submit</span>
                                       </IonButton>
                                    </div>
                                 </IonCol>
                                 <IonCol style={{ textAlign: "start" }} size="6">
                                    <IonButton
                                       className="admin_cencel_btn" fill="outline"
                                       onClick={() => { setModalopen1(false); reset() }}
                                    >
                                       <span className="button_padding">Cancel</span>
                                    </IonButton>
                                 </IonCol>
                              </IonRow>
                           </IonCol>
                        </IonRow>
                     </IonGrid>
                  </Modal.Body>
               </Modal>
               <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
            </div>
         </IonContent>
      </IonPage>
   )
}

export default MarketManagement;